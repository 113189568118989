import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  containerIframe: {
    marginBottom: "1em",
  },
}));

function Advert(props) {
  const { title, zoneid } = props;
  const classes = useStyles();



  return (
    <>
      {zoneid !== undefined  && (
        <iframe
          id="a9e4b240"
          name="a9e4b240"
          data-src={`https://revive.boringmoney.co.uk/www/delivery/afr.php?zoneid=${zoneid}&amp;cb=12345`}
          frameborder="0"
          scrolling="no"
          width="300"
          height="250"
          allow="autoplay"
          className={classes.containerIframe}
          data-cookiefirst-category="advertising"
          data-revive-type="true"
        >
          <a
            href={`https://revive.boringmoney.co.uk/www/delivery/ck.php?n=a00edce3&amp;cb=12345"`}
            target="_blank"
          >
            <img
              src={`https://revive.boringmoney.co.uk/www/delivery/avw.php?zoneid=${zoneid}&amp;cb=12345&amp;n=a00edce3"`}
              border="0"
              alt=""
            />
          </a>
        </iframe>
      )}
    </>
  );
}

export default Advert;
